<!-- 会员管理 -->
<template>
  <div>
    <div class="mainBody">
      <!--导入导出Excel-->
      <div class="create-Activity">
        <el-button @click="createActivityDialog = true">{{ $t("CreateActivity") }}</el-button>
      </div>
      <!--搜索框-->
      <div class="filterBarPay">
        <!--ID-->
        <div>
          <label>Id：</label>
          <input
            type="number"
            v-model.trim="activityId"
          >
        </div>
        <!--活动标题-->
        <div>
          <label>{{ $t("Title") }}：</label>
          <input
            type="text"
            v-model.trim="activityTitle"
          >
        </div>
        <!--活动开始时间-->
        <div>
          <label>{{$t('ActiveStart')}}：</label>
          <el-date-picker
            v-model="payStartDate"
            type="date"
            :placeholder="$t('ChooseDate')"
            format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <el-button @click="searchUserData">{{ $t('Search') }}</el-button>
      </div>

      <!--二级导航栏 全部/启用/禁用/异常-->
      <div class="navigatorPay">
        <span
          id="all"
          style="border-right:none"
          @click="active(1)"
          :class="{'active':activeUser===1}"
        >{{ $t("AllData") }}</span>
        <span
          id="start"
          style="border-right:none"
          @click="active(2)"
          :class="{'active':activeUser===2}"
        >{{ $t("Paying") }}</span>
        <span
          id="banned"
          style="border-right:none"
          @click="active(3)"
          :class="{'active':activeUser===3}"
        >{{ $t("Paid") }}</span>
        <span
          id="exception"
          @click="active(4)"
          :class="{'active':activeUser===4}"
        >{{ $t("PayCancel") }}</span>
      </div>

      <!--表格-->
      <el-table
        :data="eventList"
        border
        style="width: 99%;margin: 0 auto"
      >
        <!--id-->
        <el-table-column
          prop="eventId"
          width="180"
          align="center"
          label="Id"
        >
        </el-table-column>
        <!--活动标题-->
        <el-table-column
          prop="eventTitle"
          align="center"
          :label="$t('Title')"
        >
        </el-table-column>
        <!--缴费结束时间-->
        <el-table-column
            prop="activityPaymentTime"
            width="180px"
            align="center"
            :label="$t('PayEnd')"
        >
        </el-table-column>
        <!--活动开始时间-->
        <el-table-column
          prop="eventDate"
          width="165px"
          align="center"
          :label="$t('ActiveStart')"
        >
        </el-table-column>
        <!--发起人-->
        <el-table-column
          prop="adminName"
          width="150"
          align="center"
          :label="$t('Organizer')"
        >
        </el-table-column>
        <!--缴费金额-->
        <el-table-column
          width="130"
          align="center"
          :label="$t('PayAmount')"
        >
          <template slot-scope="scope">{{ priceOrCustomize(scope.row) }}</template>
        </el-table-column>
        <!--状态-->
        <el-table-column
          width="130"
          align="center"
          :label="$t('Status')"
        >
          <template slot-scope="scope">
            <span :class="{
                  'paying': scope.row.status === '1',
                  'pay-end': scope.row.status === '2',
                  'pay-cancel': scope.row.status === '3'
                }"
            >
              {{ numToStatusActivity(scope.row) }}
            </span>
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column
          prop="operation"
          width="210"
          align="center"
          :label="$t('Operation')"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="toPaymentDetail(scope.row.eventId)"
            >{{ $t("Detail") }}
            </el-button>
            <el-button
              type="text"
              size="small"
              style="color: red;"
              v-if="showCancel(scope.row)"
              @click="cancelActivity(scope.row.eventId)"
            >{{ $t("CancelActivity") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="userTotal"
        style="margin: 10px auto 10px 20px"
      >
      </el-pagination>
    </div>

    <!--创建活动弹窗-->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('CreateActivity')"
      :visible.sync="createActivityDialog"
      append-to-body
      :show-close="false"
      center
    >
      <el-form
        :model="createActiveForm"
        ref="createActivity"
        :rules="rules"
        class="create-activity-dialog"
        v-if="createActivityDialog"
      >
        <!--主题-->
        <el-form-item
          :label="$t('Theme')"
          prop="eventTitle"
        >
          <el-input
            v-model="createActiveForm.eventTitle"
            maxlength="500"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!--人员-->
        <el-form-item
          :label="$t('People')"
          prop="groupIds"
          class="group-picker"
        >
          <el-cascader
              :options="groups"
              :props="props"
              v-model="groupIds"
              clearable>
            <template slot-scope="{ node, data }">
              <div
                  v-if="node.level === 1"
                  class="select_group"
              >
                <span style="padding-left: 10px">{{ data.label }}</span>
                <span style="color: #D3D3D3">{{data.node}}</span>
                <!--<span style="padding-left: 10px">{{ data.label }}</span>-->
              </div>
            </template>
          </el-cascader>
        </el-form-item>
        <!--活动开始时间-->
        <el-form-item
          :label="$t('ActiveStart')"
          prop="eventDate"
        >
          <el-date-picker
            style="float: left;"
            v-model="createActiveForm.eventDate"
            type="datetime"
            :picker-options="pickerOptions"
            :placeholder="$t('ChooseDate')"
          >
          </el-date-picker>
          <!--          <el-date-picker
            v-model="createActiveForm.eventDate"
            type="date"
            :placeholder="$t('ChooseDate')"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            style="float: left;">
          </el-date-picker>-->
        </el-form-item>
        <!--缴费结束时间-->
        <el-form-item
          :label="$t('PayEnd')"
          prop="activityPaymentTime"
        >
          <el-date-picker
            style="float: left;"
            v-model="createActiveForm.activityPaymentTime"
            type="datetime"
            :picker-options="pickerOptions"
            :placeholder="$t('ChooseDate')"
          >
          </el-date-picker>
          <!--          <el-date-picker
            v-model="createActiveForm.eventDate"
            type="date"
            :placeholder="$t('ChooseDate')"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            style="float: left;">
          </el-date-picker>-->
        </el-form-item>
        <!--地点-->
        <el-form-item
            :label="$t('Address')"
            prop="eventAddress"
        >
          <el-input
              type="textarea"
              :rows="5"
              resize="none"
              v-model="createActiveForm.eventAddress"
              maxlength="5000"
              show-word-limit
              autocomplete="off"
          ></el-input>
        </el-form-item>
        <!--内容-->
        <el-form-item
          :label="$t('Content')"
          prop="content"
        >
          <el-input
            type="textarea"
            v-model="createActiveForm.content"
            maxlength="5000"
            :rows="5"
            resize="none"
            show-word-limit
          ></el-input>
        </el-form-item>
        <!--费用用途  免费活动不要费用用途-->
<!--        <el-form-item :label="$t('PayMethod')">
          <el-input
            type="textarea"
            v-model="createActiveForm.expensePurpose"
            maxlength="5000"
            :rows="5"
            resize="none"
            show-word-limit
          ></el-input>
        </el-form-item>-->
        <!--个人缴费金额-->
        <el-form-item
          :label="$t('PerPayment')"
          prop="perPay"
        >
<!--          暂时隐藏，先只能免费活动-->
<!--          <div class="per-pay">
            <el-select v-model="createActiveForm.priceType">
              <el-option
                v-for="item in moneyOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="createActiveForm.eventPrice"
              autocomplete="off"
              class="pay-amount"
              id="eventPrice"
              :placeholder="this.$t('InputMoney')"
              :disabled="createActiveForm.isCustomize"
            >{{checkPrice1(createActiveForm.eventPrice)}}</el-input>
            <el-switch
              v-model="createActiveForm.isCustomize"
              @change="customizeOrNot"
            ></el-switch>
            <span>{{$t('Customize')}}</span>
          </div>-->
          
        <!--  免费活动-->
          <div class="checkbox-overlay" @click="toggleCheckbox"></div>
          <el-checkbox v-model="freeActivity" class="free-activity">{{$t('FreeActivity')}}</el-checkbox>
        </el-form-item>
        <!--补充-->
        <el-form-item :label="$t('AdditionInfo')">
          <el-input
            type="textarea"
            v-model="createActiveForm.additional"
            maxlength="5000"
            :rows="5"
            resize="none"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="cancelCreate">{{ $t("Cancle") }}</el-button>
        <el-button
          type="primary"
          @click="createEvent"
          :loading="loading"
        >{{ $t("Confirm") }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/fetch/api";
//    import axios from 'axios'

import mixin from "@/assets/js/mixin";
import moment from "moment";

export default {
  mixins: [mixin],
  data() {
    // 活动金额
    const perPay = (rule, value, callback) => {
      if (value === "" || value === null) {
        callback(new Error(this.$t("EmptyMoney")));
      }
      // else if(!this.createActiveForm.isCustomize && this.createActiveForm.eventPrice===0){
      //   callback(new Error(this.$t('EmptyMoney')))
      // }
      else if (
        !this.createActiveForm.isCustomize &&
        this.createActiveForm.eventPrice === ""
      ) {
        callback(new Error(this.$t("EmptyMoney")));
      } else {
        callback();
      }
    };
    // 参加人员
    const group = (rule, value, callback) => {
      if (value === "" || value === null) {
        callback(new Error(value));
      }
      else if (this.groupIds.length === 0) {
        callback(new Error(this.$t("EmptyGroup")));
      } else {
        callback(value);
      }
    };
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    return {
      myRight: Number(data.financePermission),
      createActivityDialog: false,
      activeUser: 1,
      chamberId: data.chamberId,
      adminId: data.id,
      eventList: [],
      userTotal: 0,
      pageSize: 10,
      currentPage: 1,
      isNormal: true,
      loading:false,
      freeActivity:true,

      // 搜索用
      activityId: "",
      activityTitle: "",
      payStartDate: "",

      messageText: "",

      initActivity: {
        currentAdmin: data.id,
        page: 1
      },

      createActiveForm: {
        eventTitle: "",
        eventAddress: "",
        personnel: "",
        content: "",
        eventDate: "",
        activityPaymentTime:'',
        expensePurpose: "",
        eventPrice: 0,
        additional: "",
        priceType: "USD",
        isCustomize: false, // 0是  1否
        currentAdmin: data.id
      },

      moneyOptions: [
        {
          value: "USD",
          label: "USD"
        }
        // {
        //   value:'RP',
        //   label:'RP'
        // },
        // {
        //   value:'RMB',
        //   label:'RMB'
        // },
      ],
  
      // 分组
      props:{multiple:true},
      groups:[
        {
          value:'1',
          label:this.$t('AllGroup'),
        }
      ],
      groupIds:[],   // [Array,Array,Array]
      groupId:'',  // Sting形式，逗号隔开每个组
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now();
        }
      },

      rules: {
        eventTitle: [
          { required: true, message: this.$t("EmptyTheme"), trigger: "blur" }
        ],
        eventAddress: [
          { required: true, message: this.$t("EmptyAddress"), trigger: "blur" }
        ],
        groupIds:[
          { required: true, validator: group, trigger: "blur" }
        ],
        eventDate: [
          { required: true, message: this.$t("EmptyDate"), trigger: "blur" }
        ],
        activityPaymentTime: [
          { required: true, message: this.$t("EmptyDate"), trigger: "blur" }
        ],
        content: [
          { required: true, message: this.$t("EmptyContent"), trigger: "blur" }
        ],
        // perPay: [{ required: true, validator: perPay, trigger: "change" }]
      }
    };
  },
  mounted() {
    this.initEventData(1);
    this.getGroups()
    // this.createActiveForm.eventDate = moment().add(1, "day")._d;
  },
  methods: {
    //  前端： 1：全部  2：缴费中  3： 缴费结束  4： 缴费取消    后端-1
    active(num) {
      this.activeUser = num;
      this.initEventData(1);
    },

    //            弹窗：取消
    cancelCreate() {
      this.createActivityDialog = false;
      this.createActiveForm = {
        eventTitle: "",
        eventAddress: "",
        personnel: "",
        content: "",
        eventDate: "",
        expensePurpose: "",
        eventPrice: 10,
        additional: "",
        priceType: "USD",
        isCustomize: false, // 0是  1否
        currentAdmin: this.createActiveForm.currentAdmin
      };
    },

    //            初始化用户列表
    initEventData(currentPage) {
      this.currentPage = currentPage;
      this.initActivity.page = currentPage;
      this.activityId
        ? (this.initActivity.eventId = this.activityId)
        : delete this.initActivity.eventId;
      this.activityTitle
        ? (this.initActivity.eventTitle = this.activityTitle)
        : delete this.initActivity.eventTitle;
      this.payStartDate
        ? (this.initActivity.eventDate = moment(this.payStartDate).format(
            "YYYY-MM-DD"
          ))
        : delete this.initActivity.eventDate;
      this.activeUser === 1
        ? delete this.initActivity.status
        : (this.initActivity.status = this.activeUser - 1);
      api.getActivityPayment(this.initActivity).then(res => {
        this.eventList = res.data.pageList;
        this.userTotal = res.data.total;
      });
    },

    //            分页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.initEventData(currentPage);
    },
    
    // 获取会员组别
    getGroups(){
      const locale = sessionStorage.getItem("locale");
      const languageObj = {
        "zh-CN": "Chinese",
        "en-US": "English",
        "bahasa-Indonesia": "Bahasa",
      };
      let param = {
        pageNum: 1,
        pageSize: 999,
        language: languageObj[locale],
        chamberId: this.chamberId,
      }
      api.getGroupByChamberId(param).then(res=>{
        let obj = {}
        let list = []
        let source = res.data.data.records
        source.forEach(record=>{
          if(record.groupNumber!==0){
            obj = {}
            obj.value = record.groupId
            obj.label = record.groupName
            obj.node = record.node
            this.groups.push(obj)
          }
        })
        // this.groups.push(list)
      })
    },
    
    // 判断是否显示取消活动
    showCancel(obj){
      let show = true
      // 先判断活动是否已被取消
      if(Number(obj.status) === 3) return false
      // 判断时间是否已超过活动开始日期
      let diff =  moment().format('x') - moment(obj.eventDate).format('x');
      let time = moment.duration(diff);
      let days = time.days()
      if(days>0){
      //   如果已经超过活动开始日期
        show = false
      }else if(days<0){
        // 未超过
        show = true
      }else if(days === 0){
        // 如果就是今天
        let hours = time.hours()
        show = hours < 0;
      }
      return show
    },

    //            -------------------------------------------------增删改查用户-----------------------------------------------

    //            搜索用户列表
    searchUserData() {
      this.initEventData(1);
    },

    //   跳转详情页
    toPaymentDetail(eventId) {
      console.log("eventId ->", eventId);
      this.$router.push({
        name: "PaymentDetail",
        query: {
          eventId
        }
      });
    },

    //取消活动
    cancelActivity(eventId) {
      if(this.myRight<3){
        this.$message.error(this.$t('NoRight'))
      }else{
        let params = {
          eventId: eventId,
          status: 3,
          currentAdmin:this.adminId,
        };
        this.$confirm(this.$t("PayCancelPop"), this.$t("Attention"), {
          confirmButtonText: this.$t('CancelActivity'),
          cancelButtonText: this.$t('Close'),
          type: "warning"
        }).then(() => {
          api.cancelActivity(params).then(res => {
            this.active(this.activeUser);
          });
        });
      }
    },

    //   创建活动时定义自定义
    customizeOrNot() {
      if (this.createActiveForm.isCustomize) {
        delete this.createActiveForm.eventPrice;
        document
          .getElementById("eventPrice")
          .setAttribute("placeholder", this.$t("Customized"));
      } else {
        document
          .getElementById("eventPrice")
          .setAttribute("placeholder", this.$t("InputMoney"));
      }
    },

    //   创建活动
    createEvent() {
      if(this.myRight<2){
        this.$message.error(this.$t('NoRight'))
      }else{
        this.$refs.createActivity.validate(valid => {
          if (valid) {
            this.loading = true
            let createActivityApi = JSON.parse(
                JSON.stringify(this.createActiveForm)
            );
// 开始时间
            createActivityApi.eventDate = moment(
                this.createActiveForm.eventDate
            ).format("YYYY-MM-DD HH:mm:ss");
// 报名结束时间
            createActivityApi.activityPaymentTime = moment(
                this.createActiveForm.activityPaymentTime
            ).format("YYYY-MM-DD HH:mm:ss");
// 是否自定义
            this.createActiveForm.isCustomize
                ? (createActivityApi.isCustomize = 0)
                : (createActivityApi.isCustomize = 1);
            if (this.createActiveForm.isCustomize)
              delete this.createActiveForm.eventPrice;
// 参加人员
            let group = this.groupIds.flat()
            group.indexOf('1')!==-1? delete createActivityApi.groupId : createActivityApi.groupId = group+''
            api.createActivity(createActivityApi).then(res => {
              // this.createActivityDialog = false
              this.loading = false;
              this.cancelCreate();
              this.initEventData(1);
            });
          }
        });
      }
    },

    //   判断自定义还是不是自定义
    priceOrCustomize(obj) {
      // console.log('obj ->',obj)
      if (obj.isCustomize === 1) {
        return obj.price;
      } else {
        return this.$t("Customize");
      }
    },

    //   状态
    numToStatusActivity(id) {
      /**
       * 缴费中：num = 1       活动取消： num = 3     活动已开始：num = false   缴费结束但活动未开始：num = true
       * @type {boolean|number}
       */
      let num = Number(id.status) === 2? this.showCancel(id):Number(id.status)
      switch (num) {
        case 1:
          return this.$t("Paying");
        case true:
          return this.$t("Paid");
        case 3:
          return this.$t("PayCancel");
        case false:
          return this.$t("ActivityEnd");
      }
    },
  
    /**
     * 验证金额
     * @param  {[type]} cardno [description]
     * @return {[type]}     [description]
     */
    checkPrice1(val) {
      if (!val) return false;
      val = (val.toString()).replace(
          /^([1-9]\d*(\.[\d]{0,2})?|0(\.[\d]{0,2})?)[\d.]*/g,
          '$1'
      );
      //禁止录入整数部分超过7位
      val = (val.toString()).replace(/^([1-9]\d{6})\d*/g, '$1');
      //替换除了数字和.以外的字符
      val = (val.toString()).replace(/[^\d\.]/g, '');
      //第一个输入.  则自动变为0.
      val = (val.toString()).replace(/^\./g, '0.');
      //保证.只出现一次，而不能出现两次以上 2..    2.2.2
      val = (val.toString())
          .replace('.', '$#$')
          .replace(/\./g, '')
          .replace('$#$', '.');
      //只能输入两个小数
      val = (val.toString()).replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
      this.createActiveForm.eventPrice = val
    },
  
    toggleCheckbox() {
      // 当复选框不可用时，点击覆盖层不会改变其选中状态
      if (!this.isDisabled) {
        this.isChecked = !this.isChecked;
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.create-Activity {
  width: 99%;
  height: 60px;
  display: flex;
  flex-flow: row-reverse nowrap;

  .el-button {
    margin-top: 4px;
    height: 40px;
    line-height: 0.4;
    background-color: #425d7b;
    color: white;

    &:hover {
      background-color: #9fbee6 !important;
    }
  }
}

.mainBody {
  width: 1600px;
  margin-top: 5px;
}

.filterBarPay {
  width: 99%;
  height: 75px;
  margin: 5px auto 10px auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  justify-items: center;
  background-color: #677d95;
  border-radius: 5px;
  color: white;

  input {
    width: 200px;
    height: 30px;
    border: 1px solid white;
    margin: 8px 0;
    vertical-align: middle;
    float: left;
  }

  div label {
    margin: 8px 0 8px 10px;
    display: block;
    float: left;
    //line-height: 75px;
  }

  .el-button {
    width: 200px;
    height: 50px;
    line-height: 0.4;
    /*line-height: 50px;*/
    background-color: #677d95;
    color: white;

    &:hover {
      background-color: #9fbee6 !important;
    }
  }
}

.navigatorPay {
  /*width: 1660px;*/
  width: 99%;
  height: 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /*border:1px solid black;*/
  margin: 10px auto;

  & > span {
    padding-top: 3px;
    text-align: center;
    border: 1px solid black;
  }
}

.el-table th {
  background-color: #677d95;
  color: white;
  opacity: 1;
}

.active {
  //background-color: #1C252C;
  background-color: #677d95;
  color: white;
}

.table {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.create-activity-dialog {
  width: 910px;
  padding: 0 30px;

  .el-form-item {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;

    ::v-deep .el-form-item__label {
      min-width: 180px !important;
      float: left;
      text-align: center;
    }

    .el-input {
      width: 500px;
    }

    .el-textarea {
      width: 500px;
    }
  }

  .per-pay {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    .el-select {
      width: 100px;
    }
    .pay-amount {
      width: 200px;
      margin: 0 20px;
      .el-input__inner {
        width: 200px !important;
      }
    }
  }
}

.group-picker{
  
  .el-cascader{
    width: 400px;
    
  }
  
}
.select_group{
  height: 60px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.free-activity{
  
  /*::v-deep .el-checkbox__label{
    color: black;
  }*/
}
.checkbox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2; /* 使覆盖层位于复选框上方 */
  opacity: 0; /* 使覆盖层不可见 */
  cursor: not-allowed; /* 鼠标指针显示为禁止符号 */
  pointer-events: auto; /* 阻止鼠标事件传递到下面的元素 */
}
.paying{
  color: #85b32b;
}
.pay-end{
  color: black;
}
.pay-cancel{
  color: red;
}
</style>
<style lang="scss">
.el-cascader-node{
  height: 70px;
  line-height: 30px;
  outline: 0;
  
  .el-checkbox__inner{
    width: 20px;
    height: 20px;
  }
  .el-checkbox__inner::after{
    height: 10px;
    left: 7px;
  }
}
.el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
  display: none;
}
</style>


